import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import PageLayout from "../layout/PageLayout";
import {
  EditEvent,
  EventDetail,
  Events,
  UpcomingEvents,
  NewEvent,
  UserDetail,
  Users,
  EventTypes,
} from "../pages";
import { NewUser } from "../pages/Users/NewUser";
import { EditUser } from "../pages/Users/EditUser";

const RouterApp = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PageLayout />}>
          <Route index path="/" element={<UpcomingEvents />} />
          <Route path="events" element={<Events />} />
          <Route path="events/:eventId" element={<EventDetail />} />
          <Route path="events/:eventId/edit" element={<EditEvent />} />
          <Route path="events/new" element={<NewEvent />} />
          <Route path="events/types" element={<EventTypes />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:userId" element={<UserDetail />} />
          <Route path="users/new" element={<NewUser />} />
          <Route path="users/edit/:userId" element={<EditUser />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RouterApp;
