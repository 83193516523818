import {
  Event,
  EventUsers,
  EventsParams,
  EventFetch,
} from "../../models/events";
import { UpdateHostFetch, EventUsersIdsFetch } from "../../models/forms";
import { api } from "./api";

export const eventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query<Event[], EventsParams>({
      query: (params) => ({
        url: "/events",
        params: params,
      }),
    }),
    deleteEvent: build.mutation<Event[], number>({
      query: (id) => ({
        url: `/events/${id}`,
        method: "DELETE",
      }),
    }),
    getEventById: build.query<Event, string | number>({
      query: (id) => ({
        url: `/events/${id}`,
      }),
    }),
    createEvent: build.mutation<void, EventFetch>({
      query: (data) => ({
        url: "/events",
        method: "POST",
        body: data,
      }),
    }),

    updateEvent: build.mutation<void, EventFetch>({
      query: (data) => ({
        url: "/events",
        method: "PATCH",
        body: data,
      }),
    }),

    updateEventHost: build.mutation<void, UpdateHostFetch>({
      query: (data) => ({
        url: "/events/host",
        method: "PATCH",
        body: data,
      }),
    }),
    addUsersEvent: build.mutation<void, EventUsersIdsFetch>({
      query: (data) => ({
        url: "/events/addUsers",
        method: "POST",
        body: data,
      }),
    }),
    getEventUsers: build.query<EventUsers[], number | string>({
      query: (id) => ({
        url: `/events/${id}/users`,
      }),
    }),
    changeEventState: build.mutation<void, number | string>({
      query: (id) => ({
        url: `/events/${id}/state`,
        method: "PATCH",
      }),
    }),
    banEventUsers: build.mutation<void, EventUsersIdsFetch>({
      query: ({ id, usersIds }) => ({
        url: `/events/${id}/ban`,
        method: "PATCH",
        body: { usersIds },
      }),
    }),
    mutEventUsers: build.mutation<void, EventUsersIdsFetch>({
      query: ({ id, usersIds }) => ({
        url: `/events/${id}/mut`,
        method: "PATCH",
        body: { usersIds },
      }),
    }),
    deleteEventUsers: build.mutation<void, EventUsersIdsFetch>({
      query: ({ id, usersIds }) => ({
        url: `/events/${id}/users`,
        method: "DELETE",
        body: { usersIds },
      }),
    }),
    savePayments: build.mutation<void, EventUsersIdsFetch>({
      query: ({ id, usersIds }) => ({
        url: `/events/${id}/users/pay`,
        method: "PATCH",
        body: { usersIds },
      }),
    }),
    unsavePayments: build.mutation<void, EventUsersIdsFetch>({
      query: ({ id, usersIds }) => ({
        url: `/events/${id}/users/unpaid`,
        method: "PATCH",
        body: { usersIds },
      }),
    }),
  }),
});

export const {
  useCreateEventMutation,
  useGetEventsQuery,
  useDeleteEventMutation,
  useGetEventByIdQuery,
  useUpdateEventMutation,
  useUpdateEventHostMutation,
  useAddUsersEventMutation,
  useGetEventUsersQuery,
  useBanEventUsersMutation,
  useMutEventUsersMutation,
  useDeleteEventUsersMutation,
  useChangeEventStateMutation,
  useSavePaymentsMutation,
  useUnsavePaymentsMutation
} = eventsApi;
