import { useFormik } from "formik";
import * as Yup from "yup";
import { NewUserForm } from "../../models/forms";
import { yupConstants } from "../../utils/constants/yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  useCreateFictionalUserMutation,
  useUpdateFictionalUserMutation,
} from "../../store/services/users";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { EditableUser } from "../../models/users";

interface UserFormProps {
  userData?: EditableUser;

}

export const UserForm = ({ userData = undefined }: UserFormProps) => {
  const [createUser, { isLoading: isLoadingCreate }] =
    useCreateFictionalUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate }] =
    useUpdateFictionalUserMutation();
  const navigate = useNavigate();
  const { userId } = useParams();

  const goBack = () => navigate(-1);

  const { handleSubmit, touched, errors, values, handleBlur, handleChange } =
    useFormik({
      initialValues: {
        email: userData?.email || "",
        firstName: userData?.firstName || "",
        lastName: userData?.lastName || "",
      } as NewUserForm,
      validationSchema: Yup.object({
        email: Yup.string()
          .email(yupConstants().email)
          .required(yupConstants().required),
        firstName: Yup.string().required(yupConstants().required),
        lastName: Yup.string().required(yupConstants().required),
      }),
      onSubmit: (values) => {
        const { email, firstName, lastName } = values;
        if (userData && userId) {
          updateUser({
            id: parseInt(userId),
            email: email,
            firstName: firstName,
            lastName: lastName,
          })
            .unwrap()
            .then(() => {
              toast.success("Usuario editado exitosamente!");
              goBack();
            })
            .catch(() => toast.error("No se pudo editar el usuario ficticio"));
        } else {
          createUser({
            email: email,
            firstName: firstName,
            lastName: lastName,
          })
            .unwrap()
            .then(() => {
              toast.success("Usuario creado exitosamente!");
              goBack();
            })
            .catch(() => toast.error("No se pudo crear el usuario ficticio"));
        }
      },
    });

  return (
    <form
      className="flex flex-column justify-content-center align-items-center"
      onSubmit={handleSubmit}
    >
      <div className="col-12 md:col-6 my-2">
        <span className="p-float-label">
          <InputText
            className={`min-w-full ${
              touched.email && errors.email ? "p-invalid" : ""
            }`}
            id="email"
            name="email"
            keyfilter="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            disabled={isLoadingCreate || isLoadingUpdate}
          />
          <label htmlFor="email">Correo</label>
        </span>
        {touched.email && errors.email && (
          <small className="p-error">{errors.email}</small>
        )}
      </div>
      <div className="col-12 md:col-6 my-2">
        <span className="p-float-label">
          <InputText
            className={`min-w-full ${
              touched.firstName && errors.firstName ? "p-invalid" : ""
            }`}
            id="firstName"
            name="firstName"
            keyfilter="alphanum"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            disabled={isLoadingCreate || isLoadingUpdate}
          />
          <label htmlFor="firstName">Nombre/s</label>
        </span>
        {touched.firstName && errors.firstName && (
          <small className="p-error">{errors.firstName}</small>
        )}
      </div>
      <div className="col-12 md:col-6 my-2">
        <span className="p-float-label">
          <InputText
            className={`min-w-full ${
              touched.lastName && errors.lastName ? "p-invalid" : ""
            }`}
            id="lastName"
            name="lastName"
            keyfilter="alphanum"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            disabled={isLoadingCreate || isLoadingUpdate}
          />
          <label htmlFor="lastName">Apellido/s</label>
        </span>
        {touched.lastName && errors.lastName && (
          <small className="p-error">{errors.lastName}</small>
        )}
      </div>
      <div className="col-12 flex justify-content-center">
        <Button
          icon="pi pi-check"
          severity="success"
          type="submit"
          label={userData ? "Guardar" : "Crear"}
        />
      </div>
    </form>
  );
};
