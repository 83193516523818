import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer, Header } from "./components";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";
import Cookies from "js-cookie";
import { ProgressSpinner } from "primereact/progressspinner";

I18n.putVocabularies(translations);
I18n.setLanguage("es");

I18n.putVocabulariesForLanguage("es", {
  Username: "Correo Electrónico",
  "Please confirm your Password": "Confirme su contraseña",
  "Enter your Username": "Escriba su correo",
});

const PageLayout = () => {
  const [isLoading, setIsLoading] = useState(false);

  async function currentSession() {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const sessionRole =
        (session?.tokens?.accessToken?.payload[
          process.env.REACT_APP_COGNITO_GROUPS || ""
        ] as Array<string>) || [];
      const accessToken = session.tokens?.accessToken?.toString() ?? "";
      const idToken = session.tokens?.idToken?.toString() ?? "";
      const loginId = signInDetails?.loginId ?? "";
      return { accessToken, idToken, loginId, sessionRole };
    } catch (err) {
      console.error("Error fetching session: ", err);
      return { accessToken: "", idToken: "", loginId: "", sessionRole: [] };
    }
  }

  useEffect(() => {
    async function loadSession() {
      setIsLoading(true);
      const { accessToken, idToken, sessionRole } = await currentSession();

      if (!accessToken || !idToken) {
        Cookies.remove("accessToken");
        Cookies.remove("idToken");
        signOut();
      } else {
        Cookies.set("accessToken", accessToken, {
          secure: true,
          sameSite: "strict",
        });
        Cookies.set("idToken", idToken, { secure: true, sameSite: "strict" });

        if (
          !!!sessionRole.find(
            (value) => value === process.env.REACT_APP_COGNITO_ROLE
          )
        ) {
          Cookies.remove("accessToken");
          Cookies.remove("idToken");
          signOut();
        }
      }
      setIsLoading(false);
    }
    loadSession();
  }, []);

  return (
    <div className="flex flex-column justify-content-between min-h-screen surface-200">
      <section>
        <Header />
        <main className="flex flex-column p-3">
          {isLoading ? <ProgressSpinner /> : <Outlet />}
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default PageLayout;
